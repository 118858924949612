const siteOptionData = [
    {
      id: 1,
      siteName: "STARLIKE BRAND",
      image:
        "https://i.ibb.co/NFWPLbQ/app-main-logo.png",
      description: "Experience a secure and reliable VPN service with enhanced privacy and performance.",
      siteLink: "https://starlikevpn.xyz/login"
    }
  ];
  export default siteOptionData;
